import { ApolloError } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { WsEvents, useRootStore as useRootStoreOSP } from '@gimlite/osp';
import { dispatch } from '@gimlite/router';
import {
  Col,
  CountryEntry,
  Empty,
  FormType,
  Segmented,
  SegmentedType,
  Zone,
  countries,
  findAndDeleteFirstWithSameProperties,
  findAndReplaceFirstWithSameProperties,
  languages,
  requestGQL,
  useReadOf,
  useRootStore as useRootStoreWatermelon,
  useTranslation,
} from '@gimlite/watermelon';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { localMotoristUpdateGql } from '../../common/gql/local-motorist-update.gql';
import { localMotoristGql } from '../../common/gql/local-motorist.gql';
import {
  RegenerateQrcode,
  regenerateQrcodeGql,
} from '../../common/gql/regenerateQrcode.gql';
import {
  CredentialType,
  convertCredentialsBackToFront,
  convertCredentialsFrontToBack,
  downloadQrCode,
} from '../../common/mapper/credential.mapper';
import { iconGlobalEntity } from '../../common/mapper/icon.mapper';
import { LocalMotorist } from '../../common/types/entities/localMotorist';
import { CredentialCard } from '../../components/credential/credential.component';
import {
  ProfilDetails,
  ProfilDetailsType,
} from '../../components/profil-details/profil-details.component';
import { MotoristAction } from '../action/motorist.action';
import { PLATECredentialCard } from '../credential/plate.credential';
import { QRCODECredentialCard } from '../credential/qrcode.credential';
import { RFIDCredentialCard } from '../credential/rfid.credential';
import { TCSCredentialCard } from '../credential/tcs.credential';
import { MotoristContractList } from '../list/motorist-contract.list';
import { SessionList } from '../list/session.list';

type MotoristDetailsProps = {
  localMotoristId: string;
  widget?: {
    title?: string;
  };
};

export const MotoristDetails = observer(({ widget }: MotoristDetailsProps) => {
  const { t, lang } = useTranslation();
  const [segmented, setSegmented] =
    useState<SegmentedType.Data.Selected>('credentials');
  const localMotoristId = useParams().motoristId;

  const { ThemeStore: ThemeStoreWatermelon } = useRootStoreWatermelon();
  const { GlobalStore: GlobalStoreOSP } = useRootStoreOSP();

  const countryOptions = useMemo(() => {
    if (!countries || !lang || !countries[lang]) return [];
    return countries[lang].map(({ value, label }: CountryEntry) => ({
      label,
      value: value.ISO3,
    }));
  }, [countries, lang]);

  const [form, setForm] = useState<FormType.Data.Value>();

  const { details, setId } = useReadOf<LocalMotorist>({
    gql: localMotoristGql,
    cache: false,
    wsSubscriptions: [
      WsEvents.LOCAL_MOTORIST_UPDATED,
      WsEvents.MOTORIST_UPDATED,
    ],
    queryBuilder: (id) => ({ localMotoristId: id }),
    event2Id: (event) => {
      return event?.localMotorist?._id || event?.motorists?.[0]?._id || '';
    },
  });

  const isEditable = details?.status === 'VERIFIED' ? false : true;

  const badges: ProfilDetailsType.Data.Badge[] = [
    {
      text: t(
        `${
          details?.status?.toUpperCase() === 'VERIFIED'
            ? 'VERIFIED'
            : 'UNVERIFIED'
        }`,
      ),
      color: details?.status === 'VERIFIED' ? 'disabled' : 'warn',
    },
  ];

  if (details?.inside?.length)
    badges.push({
      text: t('present').toUpperCase(),
      color: 'success',
    });

  const updateLocalMotoristCredentials = useCallback(
    async ({
      localMotoristId,
      credentials,
      event,
    }: {
      localMotoristId: string;
      credentials: Array<CredentialType.CredentialFromFront>;
      event: 'DELETE' | 'CREATE' | 'UPDATE';
    }) => {
      const credentialsFormatted = convertCredentialsFrontToBack(credentials);

      try {
        await requestGQL({
          params: {
            localMotoristId: localMotoristId,
            input: {
              plates: credentialsFormatted
                .filter(({ type }) => type === 'PLATE')
                .map(({ provider, value, ...rest }) => ({
                  ...rest,
                  value: value.toUpperCase(),
                })),
              uids: credentialsFormatted.filter(({ type }) => type === 'RFID'),
              cards: credentialsFormatted.filter(
                ({ type }) => type === 'PROVIDER_EXTERNAL_ID',
              ),
            },
          },
          gql: localMotoristUpdateGql,
        });

        dispatch('NOTIF', {
          content:
            event === 'DELETE'
              ? t('theCredentialHasBeenDeleted')
              : event === 'CREATE'
              ? t('theCredentialHasBeenCreated')
              : event === 'UPDATE'
              ? t('theCredentialHasBeenUpdated')
              : '',
          mode: 'success',
        });

        return { success: true };
      } catch (error: any) {
        dispatch('NOTIF', {
          content:
            error instanceof ApolloError
              ? t(`error-code-${error.message!}`)
              : `${error}`,
          mode: 'error',
        });

        return {
          success: false,
          message:
            error instanceof ApolloError ? `${error.message}` : `${error}`,
        };
      }
    },
    [lang],
  );

  const regenerateQrcode = useCallback(
    async (motoristId: string) => {
      try {
        await requestGQL({
          params: {
            motoristId,
          } as RegenerateQrcode,
          gql: regenerateQrcodeGql,
        });

        dispatch('NOTIF', {
          content: t('theQrcodeHasBeenRegenerated'),
          mode: 'success',
        });
      } catch (error: unknown) {
        dispatch('NOTIF', {
          content:
            error instanceof ApolloError
              ? t(`error-code-${error.message!}`)
              : `${error}`,
          mode: 'error',
        });
      }

      return {};
    },
    [lang],
  );

  const allCredentials = useMemo(() => {
    if (!details) return [];

    return convertCredentialsBackToFront(
      [
        ...details.plates,
        ...details.uids,
        ...details.cards,
        ...(details?.linkedmotorist?.credentials
          ? details.linkedmotorist.credentials.filter(
              ({ type }) => type === 'QRCODE',
            )
          : []),
      ],
      details?.linkedmotorist?.qrcode,
    );
  }, [details]);

  useEffect(() => setId(localMotoristId), [localMotoristId]);

  useEffect(() => {
    if (!!details) {
      setForm({
        ...details,
        shortUid: details.linkedmotorist
          ? `${details.linkedmotorist?.consumer?.toUpperCase()}_${
              details.linkedmotorist?.shortUid
            }`
          : '',
      });
    }
  }, [details, ThemeStoreWatermelon.theme]);

  return (
    <Widget.Group
      config={{
        title: widget?.title,
        backtitle: !!widget?.title,
      }}
    >
      {localMotoristId && details && form && !details?.left ? (
        <Zone
          config={{
            zones: [['profil'], ['segmented'], ['details']],
            rows: ['min-content', 'min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'profil' }}>
            <Widget>
              <ProfilDetails
                handleEvent={{
                  submit: ({
                    _id,
                    linkedMotoristId,
                    status,
                    plates,
                    cards,
                    uids,
                    email,
                    firstName,
                    lastName,
                    address1,
                    address2,
                    zipcode,
                    city,
                    country,
                    phone,
                    lang,
                  }) => {
                    setForm({
                      _id,
                      linkedMotoristId,
                      status,
                      plates,
                      cards,
                      uids,
                      email,
                      firstName,
                      lastName,
                      address1,
                      address2,
                      zipcode,
                      city,
                      country,
                      phone,
                      lang,
                    });
                    requestGQL({
                      operationName: getOperationName(localMotoristUpdateGql)!!,
                      params: {
                        localMotoristId: details._id,
                        input: {
                          plates,
                          cards,
                          uids,
                          email,
                          firstName,
                          lastName,
                          address1,
                          address2,
                          zipcode,
                          city,
                          country,
                          phone,
                          lang,
                        },
                      },
                      gql: localMotoristUpdateGql,
                    });
                  },
                }}
                data={{
                  icon: iconGlobalEntity['motorist'],
                  form: form,
                  badges: badges,
                }}
                config={{
                  edit: isEditable,
                  form: {
                    title1: {
                      name: 'lastName',
                      placeholder: t('lastName'),
                    },
                    title2: {
                      name: 'firstName',
                      placeholder: t('firstName'),
                    },
                    description1: {
                      edit: false,
                      name: 'shortUid',
                      placeholder: t('reference'),
                    },
                    group1: [
                      {
                        name: 'phone',
                        label: t('phone'),
                        element: {
                          type: 'input',
                        },
                      },
                      {
                        name: 'email',
                        label: t('email'),
                        element: {
                          type: 'input',
                        },
                      },
                      {
                        name: 'lang',
                        label: t('language'),
                        element: {
                          type: 'select',
                          items: lang ? languages[lang] : [],
                        },
                      },
                    ],
                    group2: [
                      {
                        name: 'address1',
                        label: t('address1'),
                        element: {
                          type: 'input',
                        },
                      },
                      {
                        name: 'address2',
                        label: t('address2'),
                        element: {
                          type: 'input',
                        },
                      },
                      {
                        name: 'zipcode',
                        label: t('zipCode'),
                        element: {
                          type: 'input',
                        },
                      },
                      {
                        name: 'city',
                        label: t('city'),
                        element: {
                          type: 'input',
                        },
                      },
                      {
                        name: 'country',
                        label: t('country'),
                        element: {
                          type: 'select',
                          items: countryOptions,
                        },
                      },
                    ],
                  },
                  actions: (
                    <MotoristAction
                      config={{ size: 'xlarge' }}
                      activeContracts={true}
                    />
                  ),
                }}
              />
            </Widget>
          </Zone.Area>

          <Zone.Area config={{ area: 'segmented' }}>
            <Widget>
              <Segmented
                handleEvent={{
                  option: (value) => {
                    setSegmented(value);
                  },
                }}
                data={{ selected: segmented }}
                config={{
                  size: 'large',
                  options: [
                    { label: t('credentials'), value: 'credentials' },
                    { label: t('contracts'), value: 'contracts' },
                    { label: t('sessions'), value: 'sessions' },
                  ],
                }}
              />
            </Widget>
          </Zone.Area>

          <Zone.Area config={{ area: 'details' }}>
            {segmented === 'credentials' ? (
              <Col config={{ scrollY: true, height: 'full' }}>
                <CredentialCard.Group
                  handleEvent={{
                    event: ({ event, newValue, oldValue }) => {
                      return new Promise(async (resolve, reject) => {
                        switch (event) {
                          case 'create':
                            const tryCreate =
                              await updateLocalMotoristCredentials({
                                localMotoristId: details._id,
                                credentials: [
                                  ...allCredentials,
                                  {
                                    ...newValue,
                                  } as CredentialType.CredentialFromFront,
                                ],
                                event: 'CREATE',
                              });

                            tryCreate.success ? resolve('') : reject();
                            break;

                          case 'edit':
                            const tryEdit =
                              await updateLocalMotoristCredentials({
                                localMotoristId: details._id,
                                credentials:
                                  findAndReplaceFirstWithSameProperties(
                                    allCredentials,
                                    oldValue,
                                    newValue,
                                  ),
                                event: 'UPDATE',
                              });

                            tryEdit.success ? resolve('') : reject();

                            break;
                        }
                      });
                    },
                  }}
                  config={{ place: 'motorist' }}
                >
                  {allCredentials.map((credential, index) => {
                    switch (credential.name) {
                      case 'PLATE':
                        return (
                          <PLATECredentialCard
                            key={`credential-child-${index}`}
                            handleEvent={{
                              event: async ({ event, primaryValue }) => {
                                switch (event) {
                                  case 'delete':
                                    await updateLocalMotoristCredentials({
                                      localMotoristId: details._id,
                                      event: 'DELETE',
                                      credentials:
                                        findAndDeleteFirstWithSameProperties(
                                          allCredentials,
                                          {
                                            name: 'PLATE',
                                            plate: primaryValue!,
                                          },
                                        ),
                                    });

                                    break;
                                }
                              },
                            }}
                            data={{
                              description: credential.description,
                              plate: credential.plate,
                            }}
                          />
                        );

                      case 'RFID':
                        return (
                          <RFIDCredentialCard
                            key={`credential-child-${index}`}
                            handleEvent={{
                              event: async ({ event, primaryValue }) => {
                                switch (event) {
                                  case 'delete':
                                    await updateLocalMotoristCredentials({
                                      localMotoristId: details._id,
                                      event: 'DELETE',
                                      credentials:
                                        findAndDeleteFirstWithSameProperties(
                                          allCredentials,
                                          {
                                            name: 'RFID',
                                            uid: primaryValue!,
                                          },
                                        ),
                                    });

                                    break;
                                }
                              },
                            }}
                            data={{
                              uid: credential.uid,
                              visibleId: credential.visibleId,
                            }}
                          />
                        );

                      case 'QRCODE':
                        return (
                          <QRCODECredentialCard
                            key={`credential-child-${index}`}
                            handleEvent={{
                              event: async ({ event }) => {
                                switch (event) {
                                  case 'download':
                                    await downloadQrCode({
                                      motoristId: details.linkedmotorist._id,
                                      host: GlobalStoreOSP.BFF_MOTORIST_HOST!,
                                      token: GlobalStoreOSP.BFF_MOTORIST_TOKEN!,
                                    });
                                    break;

                                  case 're-generate':
                                    await regenerateQrcode(
                                      details.linkedmotorist._id,
                                    );
                                    break;
                                }
                              },
                            }}
                            data={{
                              generatedAt: credential.generatedAt,
                              value: credential.value,
                            }}
                          />
                        );

                      case 'TCS':
                        return (
                          <TCSCredentialCard
                            key={`credential-child-${index}`}
                            handleEvent={{
                              event: async ({ event, primaryValue }) => {
                                switch (event) {
                                  case 'delete':
                                    await updateLocalMotoristCredentials({
                                      localMotoristId: details._id,
                                      event: 'DELETE',
                                      credentials:
                                        findAndDeleteFirstWithSameProperties(
                                          allCredentials,
                                          {
                                            name: 'TCS',
                                            externalId: primaryValue!,
                                          },
                                        ),
                                    });

                                    break;
                                }
                              },
                            }}
                            data={{ externalId: credential.externalId }}
                          />
                        );

                      default:
                        return null;
                    }
                  })}
                </CredentialCard.Group>
              </Col>
            ) : segmented === 'contracts' && details?.linkedMotoristId ? (
              <MotoristContractList motoristId={details?.linkedMotoristId} />
            ) : segmented === 'sessions' && details?.linkedMotoristId ? (
              <SessionList motoristId={details.linkedMotoristId} />
            ) : (
              <></>
            )}
          </Zone.Area>
        </Zone>
      ) : (
        <Empty
          config={{
            mode: { name: 'select', text: `${t('selectMotorist')} ...` },
          }}
        ></Empty>
      )}
    </Widget.Group>
  );
});
