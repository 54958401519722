import {
  Button,
  Checkbox,
  CheckboxType,
  Col,
  Form,
  FormType,
  Input,
  InputType,
  Row,
  Select,
  Space,
  Table,
  UseCSVType,
  Zone,
  languages,
  useCSV,
  useTranslation,
} from '@gimlite/watermelon';
import { Popover } from '@gimlite/watermelon/components/popover/popover.component';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { PopupInfo } from '../../components/popup-info/popup-info.component';
import './motorists-create.form.scss';

const separator = '_|_';

export const MotoristCSVParams = (
  credentialsEdit: MotoristsCreateFormType.Config.CredentialsEdit,
): {
  fields: UseCSVType.Field<keyof Partial<MotoristsCreateFormType.Extra.CSV>>[];
  valid: (keyof MotoristsCreateFormType.Extra.CSV)[][];
} => ({
  fields: [
    ...((credentialsEdit.RFID === true
      ? [
          {
            field: 'uid',
            label: 'uid',
            type: 'string',
            example: '12345',
          },
          {
            field: 'visibleId',
            label: 'visibleId',
            type: 'string',
            example: '845623',
            min: 2,
            max: 6,
          },
        ]
      : []) as UseCSVType.Field<
      keyof Partial<MotoristsCreateFormType.Extra.CSV>
    >[]),
    ...((credentialsEdit.PLATE === true
      ? [
          {
            field: 'plate',
            type: 'string',
            label: 'plate',
            example: 'AA777BB',
            min: 1,
            max: 12,
            format: (value) =>
              !!(
                value &&
                typeof value === 'string' &&
                /^[A-Za-z0-9ÖÜÄËÏ]+$/.test(value)
              ),
          },
        ]
      : []) as UseCSVType.Field<
      keyof Partial<MotoristsCreateFormType.Extra.CSV>
    >[]),
    {
      field: 'email',
      label: 'email',
      type: 'string',
      example: 'mathieu@email.com',
      format: (value) =>
        !!(
          value &&
          typeof value === 'string' &&
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
        ),
    },
    {
      field: 'firstName',
      label: 'firstName',
      type: 'string',
      example: 'Mathieu',
    },
    {
      field: 'lastName',
      label: 'lastName',
      type: 'string',
      example: 'Lanbun',
    },
  ],
  valid: [
    [
      'lastName',
      'firstName',
      'email',
      ...((credentialsEdit.PLATE === true
        ? ['plate']
        : []) as (keyof MotoristsCreateFormType.Extra.CSV)[]),
    ],
    [
      ...((credentialsEdit.RFID === true
        ? ['uid']
        : []) as (keyof MotoristsCreateFormType.Extra.CSV)[]),
      'lastName',
      'firstName',
    ],
    [
      ...((credentialsEdit.RFID === true
        ? ['uid', 'visibleId']
        : []) as (keyof MotoristsCreateFormType.Extra.CSV)[]),
    ],
  ],
});

export declare namespace MotoristsCreateFormType {
  type Props = {
    handleEvent: {
      validate: (isValid: boolean) => void;
      submit: (data: MotoristsCreateFormType.Data.Created[]) => void;
    };
    data: {
      created: MotoristsCreateFormType.Data.Created[];
      defaultValue?: {
        sendInvitation?: boolean;
      };
    };
    config?: {
      disabled?: {
        sendInvitation?: boolean;
      };
      credentialsEdit?: {
        RFID?: boolean;
        PLATE?: boolean;
      };
      validIfEmpty?: boolean;
    };
  };

  namespace Data {
    type Created = {
      _id: string;
      uid: string | null;
      visibleId: string | null;
      plate: string | null;
      email: string | null;
      lastName: string | null;
      firstName: string | null;
      lang: string;
      sendInvitation: boolean;
    };
  }

  namespace Config {
    type CredentialsEdit = {
      RFID?: boolean;
      PLATE?: boolean;
    };
  }

  namespace Extra {
    type CSV = Pick<
      MotoristsCreateFormType.Data.Created,
      'firstName' | 'lastName' | 'plate' | 'uid' | 'email' | 'visibleId'
    >;
  }
}

export const MotoristsCreateForm = ({
  data: { created, defaultValue },
  handleEvent: { submit, validate },
  config: {
    disabled,
    credentialsEdit = { RFID: true, PLATE: true },
    validIfEmpty = false,
  } = {},
}: MotoristsCreateFormType.Props) => {
  const [form, setForm] = useState<FormType.Data.Value>({});
  const { t, lang: langTranslation } = useTranslation();

  const formFormatted = useMemo((): any[] => {
    return Object.entries(form).reduce((acc: any[], [key, value]) => {
      const keySplit = key.split(`${separator}`);
      const keyNormalize = keySplit[0];

      acc[parseInt(keySplit[1])] = {
        ...acc[parseInt(keySplit[1])],
        [keyNormalize]: value,
      };
      return acc;
    }, []);
  }, [form]);

  const CSVParams = MotoristCSVParams(credentialsEdit);

  const {
    CombinationValidNode,
    ExampleNode,
    uploadCSV,
    linesWithValidState,
    allAreValid,
  } = useCSV<MotoristsCreateFormType.Extra.CSV>({
    valid: CSVParams.valid,
    fields: CSVParams.fields.map(({ label, ...rest }) => ({
      ...rest,
      label: t(label),
    })),
    data: formFormatted,
  });

  const deleteMotoristsCreate = useCallback((index: number) => {
    setForm((currentForm: FormType.Data.Value) => {
      return Object.entries(currentForm).reduce((acc, [key, value]) => {
        const keySplit = key.split(`${separator}`);
        if (parseInt(keySplit[1]) > index) {
          return {
            ...acc,
            [`${keySplit[0]}${separator}${parseInt(keySplit[1]) - 1}`]: value,
          };
        } else if (parseInt(keySplit[1]) === index) {
          return acc;
        } else {
          return { ...acc, [key]: value };
        }
      }, {});
    });
  }, []);

  const addMotoristsCreate = useCallback(() => {
    setForm((currentForm: FormType.Data.Value) => {
      const listIndex = formFormatted.length;

      return {
        ...currentForm,
        [`_id${separator}${listIndex}`]: uuidv4(),
        [`email${separator}${listIndex}`]: null,
        [`lang${separator}${listIndex}`]: langTranslation,
        [`lastName${separator}${listIndex}`]: null,
        [`firstName${separator}${listIndex}`]: null,
        [`plate${separator}${listIndex}`]: null,
        [`sendInvitation${separator}${listIndex}`]:
          typeof defaultValue?.sendInvitation === 'boolean'
            ? defaultValue?.sendInvitation
            : false,
        [`uid${separator}${listIndex}`]: null,
        [`visibleId${separator}${listIndex}`]: null,
      };
    });
  }, [formFormatted, langTranslation]);

  const importMotoristCreate = useCallback(
    (motorists: MotoristsCreateFormType.Data.Created[]) => {
      setForm((currentForm: FormType.Data.Value) => {
        const mostHightIndex = Object.keys(currentForm).reduce((acc, key) => {
          const keySplit = key.split(`${separator}`);
          return acc < parseInt(keySplit[1]) ? parseInt(keySplit[1]) : acc;
        }, 0);

        return {
          ...currentForm,
          ...motorists.reduce((acc1, create, index) => {
            return {
              ...acc1,
              ...Object.entries(create).reduce((acc2, [key, value]) => {
                return {
                  ...acc2,
                  [`${key}${separator}${
                    (mostHightIndex === 0 ? 0 : mostHightIndex + 1) + index
                  }`]: value,
                };
              }, {}),
            };
          }, {}),
        };
      });

      validateExe(motorists);
    },
    [],
  );

  const validateExe = useCallback(
    (motoristsClean: MotoristsCreateFormType.Data.Created[]) => {
      const valid = allAreValid(
        formFormatted.map(
          ({ email, firstName, lastName, plate, uid, visibleId }) => {
            return {
              email,
              firstName,
              lastName,
              plate,
              uid,
              visibleId,
            };
          },
        ),
      );

      validate?.(valid && (validIfEmpty || formFormatted.length > 0));

      if (valid) submit(motoristsClean);
    },
    [formFormatted, validIfEmpty],
  );

  useEffect(() => {
    validateExe(formFormatted);
  }, [formFormatted]);

  useEffect(() => {
    importMotoristCreate(created);
  }, []);

  return langTranslation ? (
    <Zone
      className="motoristsCreate"
      config={{
        gap: {
          y: 1,
          x: 0,
        },
        zones: [
          ['csv', 'interaction'],
          ['table', 'table'],
        ],
        rows: ['min-content', '1fr'],
        columns: ['1fr', 'min-content'],
      }}
    >
      <Zone.Area config={{ area: 'csv' }}>
        <Col config={{ vertical: 'start' }}>
          <Row>
            <Popover
              data={
                <PopupInfo
                  data={{
                    node: CombinationValidNode,
                  }}
                  config={{
                    title: t('validCSVcombinations'),
                    description: [
                      {
                        key: 'required',
                        label: t('required'),
                        value: '*',
                        color: 'error',
                      },
                      // {
                      //   key: 'unique',
                      //   label: t('unique'),
                      //   value: '*',
                      //   color: 'info',
                      // },
                    ],
                  }}
                />
              }
            >
              <Button
                config={{
                  size: 'small',
                  mode: 'stroke',
                  text: t('CSVCombinations'),
                }}
              ></Button>
            </Popover>
            <Space config={{ count: 1, way: 'horizontal' }} />
            <Popover
              data={
                <PopupInfo
                  data={{
                    node: ExampleNode,
                  }}
                  config={{
                    title: t('CSVExample'),
                  }}
                />
              }
            >
              <Button
                config={{
                  size: 'small',
                  mode: 'stroke',
                  text: t('CSVExemple'),
                }}
              ></Button>
            </Popover>
          </Row>
        </Col>
      </Zone.Area>
      <Zone.Area config={{ area: 'interaction' }}>
        <Row config={{ width: 'full', vertical: 'center' }}>
          {/* <Row
            config={{ width: 'full', horizontal: 'center', vertical: 'center' }}
          >
            <Write
              data={{ item: 'Mon CSV contient une en-tête ?' }}
              config={{ mode: 'label', wrap: false }}
            ></Write>
            <Space config={{ count: 1, way: 'horizontal' }} />
            <Checkbox />
          </Row> */}
          <Space config={{ count: 2, way: 'horizontal' }} />
          <Button
            handleEvent={{}}
            config={{
              size: 'small',
              mode: 'fill',
              text: t('importCSV'),
              type: {
                value: 'upload',
                upload: async (files) => {
                  const uploadFile = await uploadCSV(files);
                  if (!(uploadFile instanceof Error)) {
                    importMotoristCreate(
                      uploadFile.map((object) => ({
                        ...object,
                        sendInvitation: false,
                        lang: langTranslation,
                        _id: uuidv4(),
                      })),
                    );
                  }
                },
                accept: '.csv',
                multiple: true,
              },
            }}
          ></Button>
          <Space config={{ count: 1, way: 'horizontal' }} />
          <Button
            handleEvent={{
              click: () => {
                addMotoristsCreate();
              },
            }}
            config={{
              size: 'small',
              mode: 'fill',
              text: t('addALine'),
            }}
          ></Button>
        </Row>
      </Zone.Area>
      <Zone.Area config={{ area: 'table' }}>
        <Form
          data={{ value: form }}
          handleEvent={{
            change: (value) => {
              setForm(() => value);
            },
          }}
        >
          <Table<any>
            data={{
              list: formFormatted.map(
                ({ _id, lang, sendInvitation, ...rest }, index) => {
                  const {
                    email,
                    plate,
                    firstName,
                    lastName,
                    uid,
                    visibleId,
                    valid,
                  } = linesWithValidState({ ...rest });
                  return {
                    _id: `list-${index}`,
                    valid: (
                      <Row>
                        <Space config={{ way: 'horizontal', count: 1 }}></Space>
                        {valid}
                        <Space config={{ way: 'horizontal', count: 1 }}></Space>
                      </Row>
                    ),
                    uid: (
                      <Form.Item config={{ name: `uid${separator}${index}` }}>
                        <Input
                          data={{ value: uid }}
                          config={{
                            border: false,
                            width: 'full',
                            minWidth: 'small',
                            height: 'full',
                          }}
                        />
                      </Form.Item>
                    ),
                    visibleId: (
                      <Form.Item
                        config={{ name: `visibleId${separator}${index}` }}
                      >
                        <Input
                          config={{
                            border: false,
                            width: 'full',
                            minWidth: 'small',
                            height: 'full',
                          }}
                        />
                      </Form.Item>
                    ),
                    plate: (
                      <Form.Item config={{ name: `plate${separator}${index}` }}>
                        <Input
                          config={{
                            border: false,
                            width: 'full',
                            minWidth: 'small',
                            height: 'full',
                          }}
                        />
                      </Form.Item>
                    ),
                    email: (
                      <Form.Item config={{ name: `email${separator}${index}` }}>
                        <Input
                          config={{
                            border: false,
                            width: 'full',
                            minWidth: 'medium',
                            height: 'full',
                          }}
                        />
                      </Form.Item>
                    ),
                    firstName: (
                      <Form.Item
                        config={{ name: `firstName${separator}${index}` }}
                      >
                        <Input
                          config={{
                            border: false,
                            width: 'full',
                            minWidth: 'small',
                            height: 'full',
                          }}
                        />
                      </Form.Item>
                    ),
                    lastName: (
                      <Form.Item
                        config={{ name: `lastName${separator}${index}` }}
                      >
                        <Input
                          config={{
                            border: false,
                            width: 'full',
                            minWidth: 'small',
                            height: 'full',
                          }}
                        />
                      </Form.Item>
                    ),
                    lang: (
                      <Form.Item config={{ name: `lang${separator}${index}` }}>
                        <Select
                          data={{
                            items:
                              langTranslation !== null
                                ? languages[langTranslation]
                                : [],
                          }}
                          config={{
                            border: false,
                            width: 'full',
                            minWidth: 'small',
                            height: 'full',
                          }}
                        />
                      </Form.Item>
                    ),
                    sendInvitation: (
                      <Row
                        config={{
                          vertical: 'center',
                          horizontal: 'center',
                        }}
                      >
                        <Form.Item
                          config={{
                            name: `sendInvitation${separator}${index}`,
                          }}
                        >
                          <Checkbox
                            config={{
                              disabled: disabled?.sendInvitation || false,
                            }}
                          />
                        </Form.Item>
                      </Row>
                    ),
                    action: (
                      <Row
                        config={{
                          vertical: 'center',
                          horizontal: 'center',
                        }}
                      >
                        <Space config={{ way: 'horizontal' }}></Space>
                        <Button
                          config={{ text: t('delete'), size: 'small' }}
                          handleEvent={{
                            click: () => {
                              deleteMotoristsCreate(index);
                            },
                          }}
                        ></Button>
                        <Space config={{ way: 'horizontal' }}></Space>
                      </Row>
                    ),
                  };
                },
              ),
              paging: {
                count: 1,
                current: 1,
                limit: 1,
              },
            }}
            config={{
              cellPadding: false,
              border: true,
              headColor: true,
              pagination: 'none',
              columns: [
                { key: 'valid', title: '' },
                ...(credentialsEdit.RFID === true
                  ? [
                      { key: 'uid', title: t('uid') },
                      { key: 'visibleId', title: t('visibleId') },
                    ]
                  : []),
                ...(credentialsEdit.PLATE === true
                  ? [{ key: 'plate', title: t('plate') }]
                  : []),
                { key: 'email', title: t('email') },
                { key: 'firstName', title: t('firstName') },
                { key: 'lastName', title: t('lastName'), size: 'large' },
                { key: 'lang', title: t('language') },
                { key: 'sendInvitation', title: t('sendInvitation') },
                { key: 'action', title: t('action') },
              ],
            }}
          />
        </Form>
      </Zone.Area>
    </Zone>
  ) : (
    <></>
  );
};
