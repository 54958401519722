import {
  PLATECredentialCardType,
  PLATECredentialStatic,
} from '@gimlite/osp/composition/credential/plate.credential';
import {
  QRCODECredentialCardType,
  QRCODECredentialStatic,
} from '@gimlite/osp/composition/credential/qrcode.credential';
import {
  RFIDCredentialCardType,
  RFIDCredentialStatic,
} from '@gimlite/osp/composition/credential/rfid.credential';

import {
  TCSCredentialCardType,
  TCSCredentialStatic,
} from '@gimlite/osp/composition/credential/tcs.credential';
import { IconType } from '@gimlite/watermelon';
import axios from 'axios';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { Credential } from '../types/entities/credential';
import { QRCode } from '../types/entities/qrcode';
import { useRootStore } from '../../hook/useRootStore.hook';

export declare namespace CredentialType {
  type GlobalName = 'TCS' | 'PLATE' | 'QRCODE' | 'RFID';
  type Category = Extract<
    CredentialType.GlobalName,
    'RFID' | 'QRCODE' | 'PLATE'
  >;

  type Static<Data> = {
    name: CredentialType.GlobalName;
    icon: IconType.Config.Type;
    backgroundColor: { light: string; dark: string };
    color: { light: string; dark: string };
    category: CredentialType.Category;
    primaryValue: keyof Data;
  };

  type CredentialFromFront =
    | ({
        name: Extract<CredentialType.GlobalName, 'TCS'>;
      } & TCSCredentialCardType.Data)
    | ({
        name: Extract<CredentialType.GlobalName, 'PLATE'>;
      } & PLATECredentialCardType.Data)
    | ({
        name: Extract<CredentialType.GlobalName, 'RFID'>;
      } & RFIDCredentialCardType.Data)
    | ({
        name: Extract<CredentialType.GlobalName, 'QRCODE'>;
      } & QRCODECredentialCardType.Data);

  type CredentialFromBack = Credential;
}

export const convertCredentialsBackToFrontName = ({
  type,
  provider,
}: Pick<
  CredentialType.CredentialFromBack,
  'type' | 'provider'
>): CredentialType.GlobalName | null => {
  if (type === 'QRCODE') {
    return 'QRCODE';
  } else if (
    type === 'PROVIDER_EXTERNAL_ID' &&
    provider &&
    ['TCS', 'TCSOSP'].includes(provider)
  ) {
    return 'TCS';
  } else if (type === 'PLATE') {
    return 'PLATE';
  } else if (
    type === 'RFID' ||
    (type === 'PROVIDER_EXTERNAL_ID' && provider === 'OSP')
  ) {
    return 'RFID';
  } else {
    return null;
  }
};

export const convertCredentialsBackToFront = (
  credentials: CredentialType.CredentialFromBack[],
  qrcode?: QRCode | null,
): CredentialType.CredentialFromFront[] => {
  return credentials.reduce(
    (acc: any, { type, value, description, provider }) => {
      switch (convertCredentialsBackToFrontName({ type, provider })) {
        case 'QRCODE':
          return [
            ...acc,
            {
              name: 'QRCODE',
              generatedAt: qrcode
                ? DateTime.fromISO(qrcode['createdAt']).toFormat(
                    'dd/MM/yy HH:mm:ss',
                  )
                : null,
              value: value,
            },
          ];

        case 'TCS':
          return [...acc, { name: 'TCS', externalId: value }];
        case 'PLATE':
          return [
            ...acc,
            { name: 'PLATE', plate: value, description: description },
          ];
        case 'RFID':
          return [...acc, { name: 'RFID', visibleId: description, uid: value }];
        default:
          return acc;
      }
    },
    [],
  );
};

const getSecondaryValue = (
  values: Record<string, string | null>,
  primaryValue: string,
): string | null => {
  return Object.entries(values).reduce(
    (acc: string | null, [key, value]: [string, string | null]) => {
      if (key === 'name' || key === primaryValue) return acc;
      return value;
    },
    null,
  );
};

export const convertCredentialsFrontToBack = (
  credentials: CredentialType.CredentialFromFront[],
): CredentialType.CredentialFromBack[] => {
  return credentials.map((credential) => {
    Object.entries(credential).unshift();

    switch (credential.name) {
      case 'TCS':
        return {
          type: 'PROVIDER_EXTERNAL_ID',
          provider: 'TCS',
          value: credential[TCSCredentialStatic.info.primaryValue],
          description: getSecondaryValue(
            credential,
            TCSCredentialStatic.info.primaryValue,
          ),
        };

      case 'RFID':
        return {
          type: 'RFID',
          provider: 'OSP',
          value: credential[RFIDCredentialStatic.info.primaryValue],
          description: getSecondaryValue(
            credential,
            RFIDCredentialStatic.info.primaryValue,
          ),
        };
      case 'PLATE':
        return {
          type: 'PLATE',
          provider: 'OSP',
          value: credential[PLATECredentialStatic.info.primaryValue],
          description: getSecondaryValue(
            credential,
            PLATECredentialStatic.info.primaryValue,
          ),
        };
      case 'QRCODE':
        return {
          type: 'QRCODE',
          provider: 'OSP',
          value: credential[QRCODECredentialStatic.info.primaryValue],
          description: getSecondaryValue(
            credential,
            QRCODECredentialStatic.info.primaryValue,
          ),
        };
    }
  }, []);
};

const globalSortCredentials: CredentialType.GlobalName[] = [
  'QRCODE',
  'TCS',
  'PLATE',
  'RFID',
];

export const globalSortCredentialsFn = (
  a: CredentialType.CredentialFromFront,
  b: CredentialType.CredentialFromFront,
) => {
  return (
    globalSortCredentials.indexOf(a.name) -
    globalSortCredentials.indexOf(b.name)
  );
};

export const downloadQrCode = async ({
  motoristId,
  host,
  token,
}: {
  motoristId: string;
  host: string;
  token: string;
}) => {
  return new Promise((resolve) => {
    axios({
      url: `${host}/qrcode/downloadPdf?motoristId=${motoristId}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    })
      .then((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'osp_qr_code.pdf');
        document.body.appendChild(link);
        link.click();
        resolve('');
      })
      .catch(() => resolve(''));
  });
};
