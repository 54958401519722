/* eslint-disable */
import {
  Button,
  Form,
  FormItemType,
  FormType,
  Icon,
  IconType,
  Input,
  Placement1Axis,
  Select,
  Write,
  isValidPlateBasic,
  useRootStore,
  useTranslation,
} from '@gimlite/watermelon';
import {
  Elevate,
  ElevateContext,
} from '@gimlite/watermelon/components/elevate/elevate.component';
import { Loading } from '@gimlite/watermelon/components/loading/loading.component';
import { Popover } from '@gimlite/watermelon/components/popover/popover.component';
import Color from 'color';
import { observer } from 'mobx-react-lite';
import {
  createContext,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react';
import { CredentialType } from '../../common/mapper/credential.mapper';
import { PLATECredentialStatic } from '../../composition/credential/plate.credential';
import { RFIDCredentialStatic } from '../../composition/credential/rfid.credential';
import { TCSCredentialStatic } from '../../composition/credential/tcs.credential';
import './credential.component.scss';

export declare namespace CredentialMiniType {
  type Props = {
    className?: string;
    data: {
      text?: string;
    };
    config: {
      icon: IconType.Config.Type;
      backgroundColor: string;
      color: string;
    };
  };
}

export const CredentialMini = ({
  className = '',
  data: { text },
  config: { backgroundColor, color, icon },
}: CredentialMiniType.Props) => {
  const id = useId();
  const formattedId = `credential-mini-${id.replace(/:/g, '-')}-gen`;

  return (
    <>
      <style>
        {` 
              #${formattedId} {
                .touched-path,
                .icon * {
                  fill: ${color}!important;
                }
                  .credential-touched-text {
                    color: ${color}!important;
                  }
              }
            `}
      </style>
      <div
        id={formattedId}
        style={{ backgroundColor: backgroundColor }}
        className={`credential-mini credential-mini--theme--${
          new Color(backgroundColor).isDark() ? 'dark' : 'light'
        } ${className}`}
      >
        {<Icon config={{ type: icon, size: 'xxxsmall' }} />}
        {text && (
          <Write
            className="credential-mini__value credential-touched-text"
            data={{ item: text }}
            config={{ mode: 'key-small-bold', lineHeight: true, wrap: false }}
          />
        )}
      </div>
    </>
  );
};

export declare namespace CredentialMiniGroupType {
  type Props = {
    className?: string;
    children: React.ReactNode | React.ReactNode[];
    config?: {
      hiddenMin?: number;
    };
  };
}

CredentialMini.Group = ({
  children,
  className = '',
  config: { hiddenMin = 3 } = {},
}: CredentialMiniGroupType.Props) => {
  const childrenFormatted = Array.isArray(children) ? children : [children];

  const [visible, setVisible] = useState<React.ReactNode[]>(
    childrenFormatted.slice(0, hiddenMin),
  );
  const [hidden, setHidden] = useState<React.ReactNode[]>(
    childrenFormatted.slice(hiddenMin, childrenFormatted.length),
  );

  return (
    <div className={`credential-mini-group ${className}`}>
      <div className="credential-mini-group__visible">{visible}</div>
      {hidden.length > 0 && (
        <span
          onClick={(e) => {
            e.stopPropagation();
            setVisible((acc) => [...acc, ...hidden]);
            setHidden(() => []);
          }}
          className="credential-mini-group__hidden"
        >
          ... see {hidden.length} more+
        </span>
      )}
    </div>
  );
};

export declare namespace CredentialCardType {
  type Props = {
    handleEvent?: {
      event?: CredentialCardType.HandleEvent.Event['function'];
      edit?: (data: CredentialType.CredentialFromFront) => void;
    };
    className?: string;
    data: {
      name: CredentialType.GlobalName;
      info: CredentialCardType.Data.Info[];
      category: string;
      primaryValue: CredentialCardType.Data.PrimaryValue;
    };
    config: {
      icon: IconType.Config.Type;
      backgroundColor: string;
      color: string;
      action?: CredentialCardType.Config.Action[];
      disabled?: boolean;
    };
  };

  namespace Data {
    type Info = { key: string; label: string; value: string };
    type PrimaryValue = string;
  }

  namespace Config {
    type Action = { label: string; value: string; isCritical?: boolean };
  }

  namespace HandleEvent {
    type Event = {
      value1: {
        event: string;
        name: CredentialType.GlobalName;
        primaryValue: CredentialCardType.Data.PrimaryValue | null;
      };
      return: void;
      function: (
        value: CredentialCardType.HandleEvent.Event['value1'],
      ) => CredentialCardType.HandleEvent.Event['return'];
    };
  }
}

export const CredentialCard = ({
  className = '',
  handleEvent: { event, edit } = {},
  data: { name, info, category, primaryValue },
  config: { backgroundColor, color, icon, action, disabled },
}: CredentialCardType.Props) => {
  const { t } = useTranslation();
  const id = useId();
  const formattedId = `credential-card-${id.replace(/:/g, '-')}-gen`;

  return (
    <>
      <style>
        {` 
            #${formattedId} {
              .touched-path,
              .icon * {
                fill: ${color}!important;
              }
        
              .credential-touched-text {
                color: ${color}!important;
              }
            }
          `}
      </style>

      <div id={formattedId} className={`credential-card  ${className}`}>
        <div className="credential-card__action">
          {action && action.length > 0 ? (
            action.map(
              (
                { label, value, isCritical }, //! isCritical pour les buttons importants
                index,
              ) => {
                const node = (
                  <div className="credential-card__action__button">
                    <Button config={{ text: label, size: 'medium' }} />
                  </div>
                );

                const eventFormatted = () => {
                  const findPrimaryValue = info.find(
                    ({ key }) => key === primaryValue,
                  )?.value;

                  if (value === 'edit') {
                    edit?.({
                      name,
                      ...info.reduce(
                        (
                          acc: Omit<CredentialType.CredentialFromFront, 'name'>,
                          { key, value },
                        ) => ({ ...acc, [key]: value }),
                        {},
                      ),
                    } as CredentialType.CredentialFromFront);
                  }

                  event?.({
                    event: value,
                    name,
                    primaryValue: findPrimaryValue ?? null,
                  });
                };

                return isCritical ? (
                  <Popover.Confirm
                    key={`credential-card__action__button-${index}`}
                    handleEvent={{
                      confirm: () => {
                        eventFormatted();
                      },
                    }}
                    config={{
                      title: t('importantAction'),
                      description: t('areYouSureAboutThis?'),
                    }}
                  >
                    {node}
                  </Popover.Confirm>
                ) : (
                  <div
                    key={`credential-card__action__button-${index}`}
                    onClick={() => {
                      eventFormatted();
                    }}
                  >
                    {node}
                  </div>
                );
              },
            )
          ) : (
            <span className="credential-card__action__empty">
              {t('noActionAvailable')} ...
            </span>
          )}
        </div>
        <div
          style={{ backgroundColor: backgroundColor }}
          className={`credential-card__contain ${
            disabled ? 'credential-card__contain--disabled' : ''
          }`}
        >
          <div className="credential-card__contain__head">
            <Icon config={{ type: icon, size: 'xmedium' }} />
            <span className="credential-card__contain__head__category credential-touched-text">
              {category}
            </span>
          </div>
          <div className="credential-card__contain__info">
            {info.map(({ label, value }, index) => (
              <div
                key={`credential-card__contain__info__item-${index}`}
                className="credential-card__contain__info__item"
              >
                <span className="credential-card__contain__info__item__label credential-touched-text">
                  {label} :
                </span>
                <span className="credential-card__contain__info__item__value credential-touched-text">
                  {value}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
class StaticCredentialCardGroup {
  static interpretAlign: Record<string, string> = Object.freeze({
    start: 'flex-start',
    center: 'center',
    end: 'flex-end',
  });
}

export declare namespace CredentialCardGroupType {
  type Props = {
    handleEvent: {
      event: CredentialCardGroupType.HandleEvent.Event['function'];
    };
    children: React.ReactNode | React.ReactNode[];
    className?: string;
    config: {
      place: CredentialCardGroupType.Config.Place;
      vertical?: Extract<Placement1Axis, 'start' | 'center' | 'end'>;
      credentials?: CredentialCardGroupType.Config.Credentials;
    };
  };

  namespace Config {
    type Place =
      | 'motorist'
      | 'contract'
      | 'contractDisabled'
      | 'wizardNewMotorist'
      | 'wizardMotoristExist';

    type Credentials = {
      RFID?: boolean;
      PLATE?: boolean;
      TCS?: boolean;
    };
  }

  namespace HandleEvent {
    type Event = {
      return: Promise<string>;
      function: (
        value: CredentialEditType.HandleEvent.Event['value1'],
      ) => CredentialCardGroupType.HandleEvent.Event['return'];
    };
  }
}

CredentialCard.Group = observer(
  ({
    className = '',
    children,
    handleEvent: { event },
    config: {
      place,
      vertical = 'center',
      credentials = { RFID: true, PLATE: true, TCS: true },
    },
  }: CredentialCardGroupType.Props) => {
    const { t } = useTranslation();
    const { ThemeStore } = useRootStore();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [editName, setEditName] = useState<CredentialType.GlobalName | null>(
      null,
    );
    const [currentDefaultValue, setCurrentDefaultValue] =
      useState<FormType.Data.Value>({});
    const [loading, setLoading] = useState<boolean>(false);

    const edit = useCallback(
      ({ name, ...value }: CredentialType.CredentialFromFront) => {
        setIsOpen(() => true);
        setEditName(() => name);
        setCurrentDefaultValue(() => ({
          ...value,
        }));
      },
      [isOpen],
    );

    const reset = useCallback(() => {
      setEditName(() => null);
      setCurrentDefaultValue(() => {});
      setIsOpen(false);
    }, []);

    return (
      <CredentialCard.Context.Provider value={{ place, edit }}>
        {isOpen && (
          <Elevate
            handleEvent={{
              exit: () => {
                reset();
              },
            }}
          >
            <CredentialEdit
              config={{ loading, editName }}
              handleEvent={{
                event: async (value) => {
                  setLoading(() => true);

                  try {
                    await event(value);
                    reset();
                  } catch (error) {
                    console.error(error);
                  }

                  setLoading(() => false);
                },
              }}
              data={{
                defaultValue: currentDefaultValue,
                items: [
                  ...(credentials.RFID === true
                    ? [
                        {
                          name: RFIDCredentialStatic.info.name,
                          icon: RFIDCredentialStatic.info.icon,
                          backgroundColor:
                            RFIDCredentialStatic.info.backgroundColor[
                              ThemeStore.theme
                            ],
                          color:
                            RFIDCredentialStatic.info.color[ThemeStore.theme],
                          category: RFIDCredentialStatic.info.category,
                          fields: [
                            {
                              key: 'uid',
                              label: t('uid'),
                              placeholder: '0242ac1200023044214a',
                              rules: [{ required: true }],
                            },
                            {
                              key: 'visibleId',
                              label: t('visibleId'),
                              placeholder: 'AA1238',
                              rules: [{ min: 2, max: 6 }],
                            },
                          ],
                        },
                      ]
                    : []),
                  ...(credentials.PLATE === true
                    ? [
                        {
                          name: PLATECredentialStatic.info.name,
                          icon: PLATECredentialStatic.info.icon,
                          backgroundColor:
                            PLATECredentialStatic.info.backgroundColor[
                              ThemeStore.theme
                            ],
                          color:
                            PLATECredentialStatic.info.color[ThemeStore.theme],
                          category: PLATECredentialStatic.info.category,
                          fields: [
                            {
                              key: 'description',
                              label: t('description'),
                              placeholder: 'Audi S3 Red',
                            },
                            {
                              key: 'plate',
                              label: t('plate'),
                              placeholder: 'GE777BB',
                              rules: [
                                { required: true },
                                { validator: isValidPlateBasic },
                              ],
                            },
                          ],
                        },
                      ]
                    : []),
                  ...(credentials.TCS === true
                    ? [
                        {
                          name: TCSCredentialStatic.info.name,
                          icon: TCSCredentialStatic.info.icon,
                          backgroundColor:
                            TCSCredentialStatic.info.backgroundColor[
                              ThemeStore.theme
                            ],
                          color:
                            TCSCredentialStatic.info.color[ThemeStore.theme],
                          category: TCSCredentialStatic.info.category,
                          fields: [
                            {
                              key: 'externalId',
                              label: t('externalId'),
                              placeholder: '123456789',
                              rules: [{ required: true }, { min: 3 }],
                            },
                          ],
                        },
                      ]
                    : []),
                ].filter(({ name }) => {
                  if (!editName) return true;
                  return name === editName;
                }),
              }}
            />
          </Elevate>
        )}

        <div
          style={{
            justifyContent: StaticCredentialCardGroup.interpretAlign[vertical],
          }}
          className={`credential-card-group ${className}`}
        >
          {!['wizardMotoristExist', 'contractDisabled'].includes(place) &&
            (credentials.RFID === true ||
              credentials.PLATE === true ||
              credentials.TCS === true) && (
              <div
                onClick={() => setIsOpen(true)}
                className="credential-card-group__add"
              >
                <Icon
                  className="credential-card-group__add__icon"
                  config={{
                    type: 'faPlusSolid',
                    size: 'xmedium',
                    color: 'label',
                  }}
                />
                <span className="credential-card-group__add__text">
                  {t('addACredential')}
                </span>
              </div>
            )}
          {children}
        </div>
      </CredentialCard.Context.Provider>
    );
  },
);

CredentialCard.Context = createContext<{
  place: CredentialCardGroupType.Config.Place;
  edit?: (data: CredentialType.CredentialFromFront) => void;
}>({
  place: 'motorist',
});

export declare namespace CredentialEditType {
  type Props = {
    handleEvent?: {
      event?: CredentialEditType.HandleEvent.Event['function'];
    };
    className?: string;
    data: {
      items: CredentialEditType.Data.Item[];
      defaultValue?: FormType.Data.Value;
    };
    config?: {
      loading?: boolean;
      editName?: CredentialEditType.Config.EditName;
    };
  };

  namespace Data {
    type Item = {
      name: CredentialType.GlobalName;
      icon: IconType.Config.Type;
      backgroundColor: string;
      color: string;
      category: CredentialType.Category;
      fields: Array<{
        key: string;
        label: string;
        value?: string;
        rules?: FormItemType.Config.Rule[];
        placeholder?: string;
      }>;
    };
  }

  namespace Config {
    type EditName = CredentialType.GlobalName | null;
  }

  namespace HandleEvent {
    type Event = {
      value1: {
        event: 'create' | 'edit';
        oldValue: CredentialType.CredentialFromFront;
        newValue: CredentialType.CredentialFromFront;
      };
      return: void;
      function: (
        value: CredentialEditType.HandleEvent.Event['value1'],
      ) => CredentialEditType.HandleEvent.Event['return'];
    };
  }
}

export const CredentialEdit = ({
  className = '',
  handleEvent: { event } = {},
  data: { items, defaultValue = {} },
  config: { loading = true, editName } = {},
}: CredentialEditType.Props) => {
  const { t } = useTranslation();
  const id = useId();
  const formattedId = `credential-edit-${id.replace(/:/g, '-')}-gen`;
  const [canEdit, setCanEdit] = useState<boolean>(true);

  const [currentSelected, setCurrentSelect] =
    useState<CredentialEditType.Data.Item>(items[0]);

  const { name, backgroundColor, color, fields, icon } = currentSelected;

  const isDark = useMemo(
    () => new Color(backgroundColor).isDark(),
    [currentSelected],
  );

  const selectItems = useMemo(
    () => items.map(({ name }) => ({ label: name, value: name })),
    [currentSelected],
  );

  const [currentValue, setCurrentValue] =
    useState<Omit<CredentialType.CredentialFromFront, 'name'>>();

  useEffect(() => {
    setCurrentValue(() => fields.map(({ key }) => ({ [key]: undefined })));
  }, [currentSelected]);

  if (items && items.length === 0) return null;
  return (
    <ElevateContext.Consumer>
      {({ close: closeElevate }) => (
        <>
          <style>
            {` 
          #${formattedId} {
            .touched-path,
            .icon * {
              fill: ${color}!important;
            }
      
            .credential-touched-text {
              color: ${color}!important;
            }
          }
        `}
          </style>
          <div
            id={formattedId}
            style={{ backgroundColor: backgroundColor }}
            className={`credential-edit credential-edit--theme--${
              isDark ? 'dark' : 'light'
            } ${className}`}
          >
            {loading && (
              <div className="credential-edit__loading">
                <Loading />
              </div>
            )}

            <div className="credential-edit__head">
              <Icon config={{ type: icon, size: 'xxlarge' }} />
              {!editName && (
                <Select
                  handleEvent={{
                    select: (value) => {
                      const findItem = items.find(({ name }) => name === value);
                      if (findItem) setCurrentSelect(() => findItem);
                    },
                  }}
                  data={{
                    value: name,
                    items: selectItems,
                  }}
                  config={{ backgroundColor: isDark ? 'white' : 'black' }}
                  className="credential-edit__head__category"
                ></Select>
              )}
            </div>

            <Form
              config={{
                validateOnChange: true,
              }}
              data={{
                defaultValue,
              }}
              handleEvent={{
                change: (
                  value: Omit<CredentialType.CredentialFromFront, 'name'>,
                ) => setCurrentValue(value),
                validate: (isValid) => {
                  setCanEdit(() => isValid);
                },
              }}
              className="credential-edit__form"
            >
              <div className="credential-edit__form__action">
                <Button
                  handleEvent={{ click: () => closeElevate?.() }}
                  className="credential-edit__form__action__button"
                  config={{
                    text: t('cancel'),
                    mode: 'stroke',
                    size: 'small',
                    color: isDark ? 'white' : 'black',
                  }}
                />
                <Button
                  handleEvent={{
                    click: () => {
                      currentValue &&
                        event?.({
                          event: editName ? 'edit' : 'create',
                          oldValue: {
                            name,
                            ...defaultValue,
                          } as CredentialType.CredentialFromFront,
                          newValue: {
                            name,
                            ...currentValue,
                          } as CredentialType.CredentialFromFront,
                        });
                    },
                  }}
                  className="credential-edit__form__action__button"
                  config={{
                    text: editName ? t('edit') : t('create'),
                    mode: 'fill',
                    size: 'small',
                    disabled: !canEdit,
                  }}
                />
              </div>
              <div className="credential-edit__form__entry">
                {fields.map(
                  ({ key, label, placeholder, rules, value }, index) => {
                    return (
                      <div
                        key={`credential-edit__form__entry__item-${index}`}
                        className="credential-edit__form__entry__item"
                      >
                        <Form.Item
                          key={`credential-edit__form__entry__item-${index}-${key}`}
                          data={{ defaultValue: value }}
                          config={{
                            name: key,
                            label: label,
                            color: isDark ? 'white' : 'black',
                            rules,
                          }}
                        >
                          <Input
                            config={{
                              width: 'full',
                              height: 'small',
                              backgroundColor: isDark ? 'white' : 'black',
                              placeholder,
                            }}
                          ></Input>
                        </Form.Item>
                      </div>
                    );
                  },
                )}
              </div>
            </Form>
          </div>
        </>
      )}
    </ElevateContext.Consumer>
  );
};
/* eslint-disable */
