import { dispatch } from '@gimlite/router';
import { useCallback, useMemo } from 'react';
import { Col } from '../../components/col/col.component';
import { Icon } from '../../components/icon/icon.component';
import { Popover } from '../../components/popover/popover.component';
import { Row } from '../../components/row/row.component';
import { Space } from '../../components/space/space.component';
import { Write } from '../../components/write/write.component';
import { Zone } from '../../components/zone/zone.component';
import { upload as uploadFn } from '../../functions/file.function';
import { useTranslation } from '../useTranslation.hook';
import './useCSV.hook.scss';

export declare namespace UseCSVType {
  type Field<T> = {
    field: T;
    label: string;
    unique?: boolean;
    min?: number;
    max?: number;
    length?: number;
    format?: (value: string | number | boolean | null) => boolean;
  } & (
    | {
        type: 'string';
        example: string;
      }
    | {
        type: 'number';
        example: number;
      }
  );

  type CSVObject = Record<string | number, string | undefined>;

  type Line = Record<string, string | number | null>;
}

export function useCSV<
  T extends Record<string, number | string | boolean | null>,
>({
  fields,
  valid = [],
  data,
}: {
  fields: UseCSVType.Field<keyof T>[];
  valid?: (keyof T)[][];
  data: T[];
}) {
  const { t, lang } = useTranslation();

  const CombinationValidNode = useMemo((): React.ReactNode => {
    const fieldsWithSepara = fields.reduce(
      (acc: (string | keyof T)[], { field }, index) =>
        index === fields.length - 1
          ? [...acc, field]
          : [...acc, field, `separa-${index}`],
      [],
    );

    return (
      <Zone
        config={{
          gap: {
            y: 1,
            x: 0,
          },
          zones: [...valid.map((_, index) => [`CSVcombo${index}`])],
          columns: ['1fr'],
          rows: valid.map(() => '1fr'),
        }}
      >
        {valid.map((currentValid, index) => (
          <div key={`CombinationValidNode-row-${index}`}>
            <Row config={{ vertical: 'center', horizontal: 'spaceBetween' }}>
              <Icon
                config={{
                  type: 'faCheckSolid',
                  color: 'success',
                  size: 'small',
                }}
              ></Icon>
              <Space config={{ count: 0.5, way: 'horizontal' }}></Space>
              <Write
                config={{
                  color: 'success',
                  mode: 'value-medium',
                  lineHeight: false,
                  wrap: false,
                }}
                data={{ item: `(${index + 1})` }}
              ></Write>
              <Space config={{ count: 1.5, way: 'horizontal' }}></Space>
              <Zone.Area config={{ area: `CSVcombo${index}` }}>
                <Zone
                  config={{
                    gap: {
                      y: 0,
                      x: 0.5,
                    },
                    zones: [fieldsWithSepara as string[]],
                    columns: [
                      ...[...fields, ...fields].map(
                        (_, index) => 'min-content',
                      ),
                    ],
                    rows: ['1fr'],
                  }}
                >
                  {(fieldsWithSepara as string[]).map((field, index) => (
                    <div key={`CombinationValidNode-field-${index}`}>
                      <Zone.Area config={{ area: field }}>
                        {!!(index % 2) ? (
                          <Row>
                            <Write
                              data={{ item: ';' }}
                              config={{
                                mode: 'value-small',
                                color: 'text',
                                wrap: false,
                              }}
                            />
                          </Row>
                        ) : (
                          <Row>
                            <div style={{ width: '5px' }}>
                              <Write
                                data={{
                                  item: currentValid.includes(field) ? '*' : '',
                                }}
                                config={{
                                  wrap: false,
                                  mode: 'value-small',
                                  color: currentValid.includes(field)
                                    ? 'error'
                                    : 'disabled',
                                }}
                              />
                            </div>
                            <Space
                              config={{ count: 0.25, way: 'horizontal' }}
                            />
                            {fields.find((element) => element.field == field)
                              ?.unique === true && (
                              <Write
                                data={{ item: '*' }}
                                config={{
                                  mode: 'value-small',
                                  color: 'info',
                                  wrap: false,
                                }}
                              />
                            )}
                            <Space
                              config={{ count: 0.25, way: 'horizontal' }}
                            />
                            <Write
                              data={{
                                item: fields.find(
                                  (element) => element.field === field,
                                )!.label,
                              }}
                              config={{
                                wrap: false,
                                mode: 'value-small',
                                color: 'text',
                              }}
                            />
                          </Row>
                        )}
                      </Zone.Area>
                    </div>
                  ))}
                </Zone>
              </Zone.Area>
            </Row>
          </div>
        ))}
      </Zone>
    );
  }, [valid, fields]);

  const ExampleNode = useMemo((): React.ReactNode => {
    return (
      <Zone
        config={{
          gap: {
            y: 1,
            x: 0,
          },
          zones: [...valid.map((_, index) => [`CSVcombo${index}`])],
          columns: ['1fr'],
          rows: valid.map(() => '1fr'),
        }}
      >
        <>
          <div
            onClick={() => {
              const formattedCSVExemple = valid.reduce(
                (acc: T[], itemsField) => {
                  return [
                    ...acc,
                    fields.reduce((acc, { field, example }) => {
                      return {
                        ...acc,
                        [field]: itemsField.includes(field) ? example : null,
                      };
                    }, {}) as T,
                  ];
                },
                [],
              );
              exportCSV(formattedCSVExemple);
            }}
            className="useCSV-example-download"
          >
            <Icon
              className="useCSV-example-download__icon"
              config={{
                type: 'faDownloadSolid',
                color: 'text',
                size: 'xxlarge',
              }}
            />

            <Write
              data={{ item: t('watermelon-downloadCSV') }}
              config={{ mode: 'value-small', color: 'text' }}
            />
          </div>
          {valid.map((currentValid, index) => (
            <Row key={`ExampleNode-valid-${index}`}>
              <Row config={{ vertical: 'center', horizontal: 'start' }}>
                <Icon
                  config={{
                    type: 'faVialCircleCheckSolid',
                    color: 'label',
                    size: 'small',
                  }}
                ></Icon>
                <Space config={{ count: 1.5, way: 'horizontal' }}></Space>
                {fields.map(({ field: currentField }, index) => {
                  const example = fields.find(
                    ({ field }) =>
                      field ===
                      currentValid.find((value) => value === currentField),
                  )?.example;

                  return (
                    <Row
                      config={{ width: 'initial' }}
                      key={`ExampleNode-field-${index}`}
                    >
                      {example && (
                        <Write
                          data={{
                            item: `${example}`,
                          }}
                        ></Write>
                      )}
                      <Space
                        config={{ way: 'horizontal', count: 0.25 }}
                      ></Space>
                      {index !== fields.length - 1 && (
                        <>
                          <Write
                            data={{
                              item: `;`,
                            }}
                          ></Write>
                          <Space
                            config={{ way: 'horizontal', count: 0.25 }}
                          ></Space>
                        </>
                      )}
                    </Row>
                  );
                })}
              </Row>
            </Row>
          ))}
        </>
      </Zone>
    );
  }, [valid, fields]);

  const launchError = useCallback(
    (message: string) => {
      dispatch('NOTIF', {
        content: t(message),
        mode: 'error',
      });
      return new Error(t(message));
    },
    [lang],
  );

  const uploadCSV = useCallback(
    async (files: FileList): Promise<T[] | Error> => {
      try {
        const CSVContents = await uploadFn(files, 'string');

        //! A t-on tout les fichiers ?
        if (
          CSVContents instanceof Error ||
          !CSVContents.every((value) => typeof value === 'string')
        ) {
          return launchError('WATERMELON_ERROR_FILES_UPLOAD');
        }

        const lines = CSVContents.join('\n')
          .split('\n')
          .map((line) =>
            (line.includes(';') ? line.split(';') : line.split(',')).map(
              (value) => value.trim(),
            ),
          )
          .filter((line) => !(line.length === 1 && !line[0]));

        //! Est-ce que toutes lignes correspondent au schéma déclaré par le nombre de colonnes ?
        if (!lines.every((line) => line.length === fields.length)) {
          return launchError('WATERMELON_CSV_FILE_NOT_THE_SAME_SCHEME_SIZE');
        }

        //! Est-ce que toutes lignes correspondent au schéma déclaré par le type de primitive ?
        if (
          !lines.every((line) =>
            line
              .map((value, index) => {
                return fields[index].type === 'number'
                  ? value
                    ? /^\d+$/.test(value)
                    : true
                  : true;
              })
              .every(Boolean),
          )
        ) {
          return launchError('WATERMELON_CSV_FILE_NOT_THE_SAME_SCHEME_TYPE');
        }

        //! Est-ce que toutes les lignes sont des combinaisons valides ?
        // if (
        //   !lines
        //     .map((line) =>
        //       line.reduce(
        //         (acc: string, value) => `${acc}${value ? 1 : 0}`,
        //         '0',
        //       ),
        //     )
        //     .every((lineBinary) =>
        //       valid
        //         .map((combo) =>
        //           fields.reduce(
        //             (acc: string, { field }) =>
        //               `${acc}${combo.includes(field) ? 1 : 0}`,
        //             '0',
        //           ),
        //         )
        //         .includes(lineBinary),
        //     )
        // ) {
        //   return launchError('WATERMELON_CSV_FILE_NOT_VALID_SCHEME');
        // }

        const linesFormatted = lines.map(
          (line) =>
            line.reduce(
              (acc: Partial<T>, value, index) => ({
                ...acc,
                [fields[index].field]: !value
                  ? null
                  : fields[index].type === 'number'
                  ? parseInt(value)
                  : value,
              }),
              {},
            ) as T,
        );

        //! Est-ce que les valeurs uniques sont respectées ?
        if (
          !Object.values(
            [...linesFormatted, ...data].reduce(
              (
                acc: Record<string, Array<string | boolean | null | number>>,
                line,
              ) => {
                Object.entries(line).forEach(([key, value]) => {
                  if (key in acc) {
                    if (value !== undefined) acc[key].push(value);
                  } else if (
                    !!fields.find(
                      (element) =>
                        element.field === key && element?.unique === true,
                    )
                  ) {
                    if (value !== undefined) acc[key] = [value];
                  }
                });

                return acc;
              },
              {},
            ),
          )
            .map((list) => {
              const listClear = list.filter(Boolean);
              return new Set(listClear).size === listClear.length;
            })
            .every(Boolean)
        ) {
          return launchError('WATERMELON_CSV_FILE_DUPLICATE_VALUE');
        }

        dispatch('NOTIF', {
          content: t('WATERMELON_CSV_FILE_WAS_UPLOAD'),
          mode: 'success',
        });

        return linesFormatted;
      } catch (error) {
        return launchError('WATERMELON_STRING_CSV_TO_OBJECT_JS');
      }
    },
    [lang, data, valid, fields],
  );

  const exportCSV = useCallback((dataTarget: T[]) => {
    window.open(
      URL.createObjectURL(
        new Blob(
          [
            dataTarget
              .map((object) =>
                Object.values(object)
                  .map((value) => (value === null ? '' : `${value}`))
                  .join(';'),
              )
              .join('\n'),
          ],
          { type: 'text/csv;charset=utf-8;' },
        ),
      ),
    );
  }, []);

  const sortCSV = useCallback(
    (array: Array<Record<keyof T, any>>): Record<keyof T, any>[] => {
      const header = fields.map((element) => element.field);

      return array.map((line) => {
        return Object.fromEntries(
          Object.entries(line).sort(
            ([aKey], [bKey]) => header.indexOf(aKey) - header.indexOf(bKey),
          ),
        ) as Record<keyof T, any>;
      });
    },
    [fields],
  );

  const currentCombinaisonFn = useCallback(
    (line: T): { currentCombinaison: (keyof T)[]; index: number } | null => {
      const currentCombinaison = Object.entries(line).reduce(
        (acc: Array<keyof T>, [key, value]) => {
          return value ? [...acc, key] : acc;
        },
        [],
      );

      const findValidCombinaison = valid.findIndex((combinaison) =>
        combinaison.every((item) => currentCombinaison.includes(item)),
      );

      return findValidCombinaison !== -1
        ? {
            currentCombinaison: valid[findValidCombinaison],
            index: findValidCombinaison + 1,
          }
        : null;
    },
    [valid],
  );

  const validFieldFn = useCallback(
    ({
      combinaison,
      line,
    }: {
      combinaison: (keyof T)[];
      line: T;
    }): { messages: string[]; isValidField: boolean } => {
      const messages = fields
        .map(({ field }) => ({
          key: field,
          isRequired: combinaison.includes(field),
        }))
        .flatMap(({ key, isRequired }: any) => {
          //! TS dis que ce n'est pas forcément un string, je sais pas pourquoi
          const specification = fields.find(
            (element) => element.field === key,
          )!;

          const value = line[key];

          const messages: string[] = [];

          if (!value && isRequired) {
            messages.push(
              t('watermelon-CSV_VALIDATION_REQUIRED').replace('[key]', key),
            );
          }

          if (!value && !isRequired) return messages;

          if (
            specification?.length &&
            `${value}`.length !== specification.length
          ) {
            messages.push(
              t('watermelon-CSV_VALIDATION_LENGTH')
                .replace('[key]', key)
                .replace('[length]', `${specification.length}`),
            );
          }

          if (specification?.min && `${value}`.length < specification.min) {
            messages.push(
              t('watermelon-CSV_VALIDATION_MIN_LENGTH')
                .replace('[key]', key)
                .replace('[min]', `${specification.min}`),
            );
          }

          if (specification?.max && `${value}`.length > specification.max) {
            messages.push(
              t('watermelon-CSV_VALIDATION_MAX_LENGTH')
                .replace('[key]', key)
                .replace('[max]', `${specification.max}`),
            );
          }

          if (
            specification.type === 'number' &&
            typeof value === 'string' &&
            /\D/.test(value)
          ) {
            messages.push(
              t('watermelon-CSV_VALIDATION_NUMBER').replace('[key]', key),
            );
          }

          if (specification.format) {
            const isCorrectFormat = specification.format(value);

            if (!isCorrectFormat) {
              messages.push(
                t('watermelon-INVALID_FORMAT').replace('[key]', key),
              );
            }
          }

          return messages;
        });

      return { messages, isValidField: messages.length > 0 ? false : true };
    },
    [lang, fields],
  );

  const linesWithValidState = useCallback(
    (line: T): T & { valid: React.ReactNode } => {
      const combinaison = currentCombinaisonFn(line);

      const { isValidField, messages } = validFieldFn({
        combinaison: combinaison ? combinaison.currentCombinaison : [],
        line,
      });

      const nodeContain = (
        <Row config={{ horizontal: 'center', vertical: 'center' }}>
          <Icon
            config={{
              type:
                combinaison && isValidField ? 'faCheckSolid' : 'faXmarkSolid',
              color: combinaison && isValidField ? 'success' : 'error',
            }}
          ></Icon>
          <Space config={{ count: 0.5, way: 'horizontal' }}></Space>

          <Write
            config={{
              color: combinaison && isValidField ? 'success' : 'error',
              mode: 'value-medium',
              lineHeight: false,
            }}
            data={{
              item: `(${combinaison ? combinaison.index : '?'})`,
            }}
          ></Write>
        </Row>
      );

      return {
        ...line,
        valid:
          combinaison && isValidField ? (
            nodeContain
          ) : (
            <Row
              config={{
                horizontal: 'center',
                vertical: 'center',
                width: 'full',
              }}
            >
              <Popover
                data={
                  <Col
                    config={{
                      horizontal: 'center',
                      vertical: 'center',
                      width: 'full',
                    }}
                  >
                    {combinaison ? (
                      messages.map((message, index) => {
                        return (
                          <Col key={`summaryValidation-${index}`}>
                            <Write
                              data={{ item: message }}
                              config={{ mode: 'value-small', color: 'error' }}
                            ></Write>
                            {index !== messages.length - 1 && (
                              <Space config={{ way: 'vertical', count: 0.8 }} />
                            )}
                          </Col>
                        );
                      })
                    ) : (
                      <Write
                        data={{ item: t('watermelon-INVALID_COMBINAISON') }}
                        config={{ mode: 'value-small', color: 'error' }}
                      ></Write>
                    )}
                  </Col>
                }
                config={{ trigger: 'hover' }}
              >
                {nodeContain}
              </Popover>
            </Row>
          ),
      };
    },
    [valid, fields, lang],
  );

  const allAreValid = useCallback(
    (lines: T[]): boolean => {
      return lines
        .map((line) => {
          const combinaison = currentCombinaisonFn(line);

          if (!combinaison) return false;

          const { isValidField } = validFieldFn({
            combinaison: combinaison ? combinaison.currentCombinaison : [],
            line,
          });

          return isValidField;
        })
        .every(Boolean);
    },
    [valid, fields],
  );

  return {
    CombinationValidNode,
    ExampleNode,
    uploadCSV,
    exportCSV,
    sortCSV,
    linesWithValidState,
    allAreValid,
  };
}
