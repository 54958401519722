import { PLATECredentialMini } from '@gimlite/osp/composition/credential/plate.credential';
import { QRCODECredentialMini } from '@gimlite/osp/composition/credential/qrcode.credential';
import { RFIDCredentialMini } from '@gimlite/osp/composition/credential/rfid.credential';
import { TCSCredentialMini } from '@gimlite/osp/composition/credential/tcs.credential';
import { EnteredSessionTimeline } from '@gimlite/osp/composition/session-timeline/ENTERED.session-timeline';
import { EntryAuthorizedSessionTimeline } from '@gimlite/osp/composition/session-timeline/ENTRY-AUTHORIZED.session-timeline';
import { EntryDeniedSessionTimeline } from '@gimlite/osp/composition/session-timeline/ENTRY-DENIED.session-timeline';
import { ExitAuthorizedSessionTimeline } from '@gimlite/osp/composition/session-timeline/EXIT-AUTHORIZED.session-timeline';
import { ExitDeniedSessionTimeline } from '@gimlite/osp/composition/session-timeline/EXIT-DENIED.session-timeline';
import { ExitedSessionTimeline } from '@gimlite/osp/composition/session-timeline/EXITED.session-timeline';
import { PaymentNotifiedSessionTimeline } from '@gimlite/osp/composition/session-timeline/PAYMENT-NOTIFIED.session-timeline';
import { PedestrianAccessSessionTimeline } from '@gimlite/osp/composition/session-timeline/PEDESTRIAN-ACCESS.session-timeline';
import { ReactElement } from 'react';
import { Credential } from '../types/entities/credential';
import { Event } from '../types/entities/event';

const CredentialComponentSelector = (credentials: Credential[]) => {
  const externalId = credentials.find(
    ({ type }) => type === 'PROVIDER_EXTERNAL_ID',
  );
  const mapedCredentialToComponent = ({
    type,
    value,
  }: {
    type: string;
    value: string;
  }) => {
    const credentialMap: { [key: string]: JSX.Element } = {
      RFID: <RFIDCredentialMini data={{ value: value }} />,
      PLATE: <PLATECredentialMini data={{ value: value }} />,
      QRCODE: <QRCODECredentialMini data={{ value: '' }} />,
      TCS: <TCSCredentialMini data={{ value: value }} />,
      TCSOSP: <TCSCredentialMini data={{ value: value }} />,
    };
    return credentialMap[type] ? credentialMap[type] : '';
  };
  return externalId
    ? mapedCredentialToComponent({
        type: externalId.provider || 'OSP',
        value: externalId.value,
      })
    : mapedCredentialToComponent({
        type: credentials[0]?.type || '',
        value: credentials[0]?.value || '',
      });
};

export const ComponentInfosSelector = ({
  state,
  createdAt,
  credentials,
  accessPointCode,
  reason,
  amount,
  currency,
}: Event) => {
  const mapedStateToComponent: { [key: string]: ReactElement } = {
    ENTRY_AUTHORIZED: (
      <EntryAuthorizedSessionTimeline
        key={crypto.randomUUID()}
        data={{
          date: createdAt,
          device: accessPointCode,
          extra: CredentialComponentSelector(credentials),
        }}
      />
    ),
    EXIT_AUTHORIZED: (
      <ExitAuthorizedSessionTimeline
        key={crypto.randomUUID()}
        data={{
          date: createdAt,
          device: accessPointCode,
          extra: CredentialComponentSelector(credentials),
        }}
      />
    ),
    EXITED: (
      <ExitedSessionTimeline
        key={crypto.randomUUID()}
        data={{
          date: createdAt,
          device: accessPointCode,
        }}
      />
    ),
    ENTRY_DENIED: (
      <EntryDeniedSessionTimeline
        key={crypto.randomUUID()}
        data={{
          date: createdAt,
          device: accessPointCode,
          extra: CredentialComponentSelector(credentials),
          subtitle: reason || 'undefined',
        }}
      />
    ),
    ENTERED: (
      <EnteredSessionTimeline
        key={crypto.randomUUID()}
        data={{
          date: createdAt,
          device: accessPointCode,
        }}
      />
    ),
    EXIT_DENIED: (
      <ExitDeniedSessionTimeline
        key={crypto.randomUUID()}
        data={{
          date: createdAt,
          device: accessPointCode,
          extra: CredentialComponentSelector(credentials),
          subtitle: reason || 'undefined',
        }}
      />
    ),
    AUTHORIZE_PEDESTRIAN: (
      <PedestrianAccessSessionTimeline
        key={crypto.randomUUID()}
        data={{
          date: createdAt,
          device: accessPointCode,
          extra: CredentialComponentSelector(credentials),
        }}
      />
    ),
    PAYMENT_NOTIFIED: (
      <PaymentNotifiedSessionTimeline
        key={crypto.randomUUID()}
        data={{
          date: createdAt,
          device: '-',
          extra:
            amount && currency
              ? new Intl.NumberFormat(currency, {
                  style: 'currency',
                  currency: currency,
                }).format(amount / 100)
              : currency
              ? new Intl.NumberFormat(currency, {
                  style: 'currency',
                  currency: currency,
                }).format(0)
              : '-',
        }}
      />
    ),
  };
  return mapedStateToComponent[state];
};
